<template>
  <div>
    <h2 class="text-center font-weight-bolder text-dark font-size-h4 font-size-h1-lg mb-12">
      Silahkan Lakukan Aktivasi/Perpanjangan
    </h2>
    <b-form id="form" @submit="onSubmit">
      <b-form-group id="input-group-sktAktif" label-for="inputSktAktif">
        <template v-slot:label>
          No. SKT Aktif <span class="text-danger">*</span>
        </template>
        <b-form-input id="inputSktAktif" name="inputSktAktif" v-model="$v.form.no_skt.$model"
          placeholder="Silahkan Masukan Nomor SKT Aktif" :state="validateState('no_skt')"
          aria-describedby="inputSktAktif"></b-form-input>

        <b-form-invalid-feedback id="inputSktAktif">Nomor SKT Aktif harus diisi!</b-form-invalid-feedback>
      </b-form-group>

      <b-form-group id="input-group-sktLama" label="No. SKT Lama" label-for="inputSktLama">
        <b-form-input id="inputSktLama" name="inputSktLama" v-model="$v.form.no_skt_lama.$model"
          placeholder="Silahkan Masukan Nomor SKT Lama" aria-describedby="inputSktLama"></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-noRegistrasi" label-for="inputNoRegistrasi">
        <template v-slot:label>
          No. Registrasi <span class="text-danger">*</span>
        </template>
        <b-form-input id="inputNoRegistrasi" name="inputNoRegistrasi" v-model="$v.form.no_registrasi.$model"
          placeholder="Silahkan Masukan Nomor Registrasi SIOLA" :state="validateState('no_registrasi')"
          aria-describedby="inputNoRegistrasi"></b-form-input>

        <b-form-invalid-feedback id="inputNoRegistrasi">Nomor Registrasi harus diisi!</b-form-invalid-feedback>
      </b-form-group>

      <b-form-group id="input-group-noKtp" label-for="inputNoKtp">
        <template v-slot:label>
          No. KTP <span class="text-danger">*</span>
        </template>
        <b-form-input id="inputNoKtp" name="inputNoKtp" v-model="$v.form.no_ktp.$model"
          placeholder="Silahkan Masukan Nomor KTP" :state="validateState('no_ktp')" aria-describedby="inputNoKtp">
        </b-form-input>

        <b-form-invalid-feedback id="inputNoKtp">Nomor KTP harus diisi!</b-form-invalid-feedback>
      </b-form-group>

      <b-form-group id="input-group-namaLengkap" label-for="inputNamaLengkap">
        <template v-slot:label>
          Nama Lengkap Pendaftar <span class="text-danger">*</span>
        </template>
        <b-form-input id="inputNamaLengkap" name="inputNamaLengkap" v-model="$v.form.nama_lengkap.$model"
          placeholder="Silahkan Masukan Nama Lengkap Pendaftar" :state="validateState('nama_lengkap')"
          aria-describedby="inputNamaLengkap"></b-form-input>

        <b-form-invalid-feedback id="inputNamaLengkap">Nama Lengkap Pendaftar harus diisi!</b-form-invalid-feedback>
      </b-form-group>

      <b-form-group id="input-group-emailPendaftar" label-for="emailPendaftar">
        <template v-slot:label>
          Email Pendaftar <span class="text-danger">*</span>
        </template>
        <b-form-input id="emailPendaftar" name="emailPendaftar" v-model="$v.form.email.$model"
          placeholder="Silahkan Masukan Email Pendaftar" :state="validateState('email')"
          aria-describedby="emailPendaftar"></b-form-input>

        <b-form-invalid-feedback id="emailPendaftar">Email Pendaftar harus diisi!</b-form-invalid-feedback>
      </b-form-group>

      <b-form-group id="input-group-noHp" label-for="noHp">
        <template v-slot:label>
          No. HP <span class="text-danger">*</span>
        </template>
        <b-form-input id="noHp" name="noHp" v-model="$v.form.no_hp.$model" placeholder="Silahkan Masukan Nomor HP"
          :state="validateState('no_hp')" aria-describedby="noHp"></b-form-input>

        <b-form-invalid-feedback id="noHp">Nomor HP harus diisi!</b-form-invalid-feedback>
      </b-form-group>

      <div class="text-centerAktivasi">
        <b-button type="button" class="button-batal tombol-DiAktivasi" style="margin-right: 2.5% !important"
          @click="$router.push('/')">Kembali</b-button>
        <b-button type="submit" class="button-aktivasi tombol-DiAktivasi" style="margin-left: 2.5% !important">
          <span v-if="!loading">Aktivasi</span>
          <b-spinner medium v-else></b-spinner>
        </b-button>

      </div>
    </b-form>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "ActivationFloat",
  props: ["length"],
  mixins: [validationMixin],
  data() {
    return {
      form: {
        no_skt: "",
        no_skt_lama: "",
        no_registrasi: "",
        no_ktp: "",
        nama_lengkap: "",
        email: "",
        no_hp: "",
      },
      loading: false
    };
  },
  validations: {
    form: {
      no_skt_lama: {
        // required
      },
      nama_lengkap: {
        required
      },
      no_ktp: {
        required
      },
      no_skt: {
        required
      },
      no_registrasi: {
        required
      },
      email: {
        required
      },
      no_hp: {
        required
      }
    }
  },
  mounted() {
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        no_skt: null,
        no_skt_lama: null,
        no_registrasi: null,
        nama_lengkap: null,
        email: null,
        no_hp: null,
        no_ktp: null
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    onSubmit(e) {
      e.preventDefault();
      this.$v.form.$touch();
      if (!this.$v.form.$invalid) {
        
        this.loading = true
        let data = {
        no_skt: this.form.no_skt,
        no_skt_lama: this.form.no_skt_lama,
        no_ktp: this.form.no_ktp,
        nama_lengkap: this.form.nama_lengkap,
        no_registrasi: this.form.no_registrasi,
        email: this.form.email,
        no_hp: this.form.no_hp,
        url_callback: process.env.VUE_APP_APPLICATION_BASE_URL + "/buatsandi",
        url_login: process.env.VUE_APP_APPLICATION_BASE_URL + "/login"
      };

      this.$store
      .dispatch("aktivasiAkun", data)
      .then((res) => {
        if(!res.data.status){
          let propertyValues = Object.values(res.data.error);
          var bb = ''
          for(var i = 0; i < propertyValues.length; i++){ 
            bb += ' ' + propertyValues[i] + ','
          }
          bb.slice(0, -1)
          Swal.fire({
            title: res.data.message,
            text: bb,
            icon: "warning",
            confirmButtonColor: "#063A69",
            confirmButtonText: "Ok",
            cancelButtonText: false
          })
        } else {
          this.form = {
            no_skt: "",
            no_skt_lama: "",
            no_registrasi: "",
            no_ktp: "",
            nama_lengkap: "",
            email: "",
            no_hp: "",
          }
          Swal.fire({
            title: "Aktivasi Selesai",
            text:
              "Silahkan konfirmasi akun anda melalui email yang telah kami kirim",
            confirmButtonColor: "#063A69",
            confirmButtonText: "Kembali Ke Beranda",
            cancelButtonText: false
          }).then((result) => {
            if (result.isConfirmed) {
              this.$router.push('/');
            }
          });
          this.loading = false
          this.resetForm()
        }
        this.loading = false
      }).catch((error) => {
        this.errorMessage = error;
        this.loading = false
        Swal.fire({
          title: error.message,
          text: "",
          icon: "warning",
          confirmButtonColor: "#063A69",
          confirmButtonText: "Ok",
          cancelButtonText: false
        })
      })
        if (this.$v.form.$anyError) {
          return false;
        }
      }
    }
    
  }
};
</script>

<style scoped>


.tombol-DiAktivasi {
  width: 29.917%;
  height: auto !important;
  margin-left: 0px !important;
  text-align: center;
  padding: 3% !important;
}

.text-centerAktivasi {
  text-align: center !important;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}


.button-aktivasi {
  margin-top: 50px;
  background: #063a69;
  color: #ffffff;
  box-shadow: 0 4px 15px rgba(5, 58, 105, 0.25);
  border-radius: 10px;
  padding: 16px 60px;
}
.button-batal {
  margin-top: 50px;
  background: white;
  color: #063a69;
  border: 1px solid #063a69;
  box-shadow: 0 4px 15px rgba(5, 58, 105, 0.25);
  border-radius: 10px;
  padding: 16px 60px;
  margin-right:24px;

}
@media screen and (min-device-width: 240px) and (min-width: 240px) and (max-device-width: 640px) and (max-width: 640px) and (-webkit-min-device-pixel-ratio: 2) {
.button-batal {
  display: none;
}
}
</style>
